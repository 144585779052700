/* eslint-disable @typescript-eslint/no-explicit-any */
export const Vocabulary = {
  login: "Login",
  logout: "Deconectare",

  userName: "Utilizator",
  password: "Parola",
  resetPassword: "Resetare Parolă",
  email: "Email",
  incorrectEmail: "Email incorect",
  phone: "Telefon",

  edit: "Editează",
  add: "Adaugă",
  search: "Caută",
  delete: "Șterge",
  finish: "Gata",
  save: "Salvează",
  cancel: "Anulează",
  yes: "Da",
  no: "Nu",

  name: "Nume",
  type: "Tip",
  settings: "Setări",
  options: "Opțiuni",
  option: "Opțiune",

  pageNotFound: "Pagina nu a fost gasită!",
  dashboard: "Panou de control",

  success: "Succes",
  addError: "Eroare la adăugare",
  addSuccess: "Adăugat cu succes!",
  deleteError: "Eroare la ștergere!",
  deleteSuccess: "Șters cu succes!",
  saveError: "Eroare la salvarea!",
  saveSuccess: "Salvat cu succes!",
  getError: "Eroare la aducerea datelor",
  requiredField: "Acest câmp este obligatoriu",
  required: "Obligatoriu",
  noResultsFound: "Nu a fost găsit nici un rezultat",

  //devices
  devices: "Dispozitive",
  newDevice: "Dispozitiv nou ",
  deviceName: "Nume dispozitiv",
  deviceType: "Tipul dispozitivului",
  manufacturer: "Producător",
  model: "Model",
  storage: "Stocare",
  color: "Culoare",
  addNewImages: " Adăugati noi imagini",
  chooseDevice: "Alegeți un dispozitiv!",
  pleaseAddImage: "Vă rugăm adăugați imagini",
  paymentMethod:
    "Metoda exclusivă pentru a primi banii este prin contul bancar",
  pleaseAddStorageAndColor:
    "Vă rugăm selectați memoria de stocare și culoarea!",
  deleteDevice: "Sunteți sigur că doriți să ștergeti acest dispozitiv?",
  params: "Parametrii",
  excelImport: "Import excel",
  devicesImport: "Importă dispozitive",
  import: "Importă",
  Tablet: "Tabletă",
  Phone: "Telefon",
  Watch: "Ceas",
  Laptop: "Laptop",
  reports: "Rapoarte",
  change: "Modifică",

  //orders
  orders: "Comenzi",
  deleteOrder:
    " Sunteți sigur că doriți să ștergeti acestă comandă/aceste comenzi?",
  numberOrder: "Număr comandă",
  questions: "Întrebări",
  priceAndPayment: "Prețul și plata",
  price: "Preț",
  currency: "LEI",
  currencyName: "Monedă",
  status: "Status",
  inProgress: "În procesare",
  estimated: "Estimat",
  technicalVerification: "Verificare tehnică",
  confirmedFinalOffer: "Confirmare ofertă finală",
  return: "Retur",
  paymentDone: "Plată efectuată",
  date: "Dată",
  device: "Dispozitiv",
  order: "Comandă",
  payment: "Plată",
  delivery: "Livrare",
  unsentMail: "Mail netrimis",
  applyFilters: "Aplică filtre",
  paymentType: "Tipul vânzării",
  trashedOrders: "Comenzi șterse",
  restore: "Recuperați",
  recoverOrders: "Recuperați comenzile",
  restoreTrashedOrders:
    "Sunteți sigut că doriți să recuperati aceste comenzi/ această comandă?",
  deleteOrderPermanent:
    " Sunteți sigur că doriți să ștergeti permanent acestă comandă/ aceste comenzi?",
  totalOrders: "Total comenzi",
  downloadExcel: "Descarcă excel",
  downloadPdf: "Descarcă pdf",
  estimatedPrice: "Preț estimat",
  ordersByStatus: "Comenzi în funcție de status",

  //users
  users: "Utilizatori",
  newUser: "Utilizator nou",
  admin: "Administrator",
  user: "Utilizator",
  serviceTechnician: "Tehnician service",
  notTheSame: "Parolele nu sunt la fel",
  roles: "Roluri",
  changePassword: "Schimbați parola",
  generate: "Generează",
  confirmPassword: "Confirmați parola",
  deleteUser: "Ștergeți utilizatorul",
  deleteUserText: "Sigur vreți să ștergeți utilizatorul?",

  //questionnaires
  questionnaires: "Chestionare",
  questionnaire: "Chestionar",
  newQuestionnaire: "Chestionar nou",
  questionnaireName: "Nume chestionar",
  addImages: "Adăugați imagini",
  saveAsTemplateText: "Doriți să salvați acest chestionar ca template?",
  saveAsTemplate: "Salvați ca template",
  newQuestion: "Întrebare nouă",
  coefficient: "Coeficient",
  hardStop: "Oprire forțată",
  addNewAnswer: " Adăugați un nou răspuns",
  coefficientType: " Tip coeficient",
  singleChoice: "O singură varinată",
  multipleChoice: "Variante multiple",
  answer: "Răspuns",
  question: "Întrebarea",
  editQuestionnaire: "Editează chestionare",
  selectPersonalDataError:
    "Va rugăm selectați doar două șabloane pentru datele personale!",
  selectTemplate: "Selectați un șablon pentru datele personale!",
  deleteQuestionnaire:
    "Sunteți sigur că doriți să ștergeti acest chestionar? Toate dispozitivele asociate acestui chestionar nu vor mai avea chestionar asociat.",

  //templates
  templateName: "Nume șablon",
  paymentInfo: "Informații plată",
  questionnairesTemplates: "Șabloane chestionare",
  templates: "Șabloane",
  template: "Șablon",
  newTemplate: "Șablon nou",
  personalData: "Date personale",
  personalDataTemplates: "Șabloane date personale",
  personalDataTemplate: "Șablon date personale",
  "templates/questionnaires": "Șabloane chestionare",
  "templates/personalData": "Șabloane date personale",
  "templates/emails": "Șabloane email-uri",
  personalDataCollectionAgreement:
    "Consimțământ colectare date cu carater personal",
  useTemplate: "Doriți să folosiți un șablon?",
  deleteTemplates: " Sunteți sigur că doriți să ștergeti acest șablon?",
  deletePersonalDataTemplates:
    "Nu puteți șterge acest șablot deoarece este asociat unui chestionare. Ștergeți chestionarul prima data.",
  terms: "Termeni și condiții",
  fieldGroup: "Grup de câmpuri",
  hint: "Indiciu",
  text: "Text",
  number: "Numar",
  addField: "Adauga camp",
  emails: "Email-uri",
  additionalData: "Date adiționale",
  emailTemplateName: "Nume șablon email",
  addDynamicData: "Adaugă date dinamic pentru subiect și corpul email-ului:",
  emailError: "Vă rugăm selectati un status",
  subject: "Subiect",
  cc: "CC",
  editor: "Edit",

  //orderStatus
  newOrderStatus: "Status nou",
  orderStatus: "Status comandă",
  editOrderStatus: "Editează statusul comenzii",
  addOrderStatus: "Adaugați un nou status pentru comenzi",
  statusOrderDevice: "Sunteți sigur că doriți să ștergeti acest status?",
  startDate: "Data început",
  endDate: "Data sfârșit",
  //
  administration: "Administrare",
  insert: "Creat",
  insertMany: "Inserat mai multe",
  updateMany: "Actulizate mai multe",
  update: "Actualizat",
  recover: "Recupat",
  softDelete: "Marcat ca șters",
  // delete: "Șters",
  method: "Acțiune",
  table: "Tabel",
  object: "Obiect",
  emailTemplates: "Email",
  questionnaireTemplates: "Șabloane chestionar",
  logs: "Logs",
  filter: "Filtrează",
  visibility: "Vizualizează",
  updatedAt: "Actualizat la",
  accountING: "Cont bancar ING",
  accountRaiffeisen: "Cont bancar Raiffeisen",
  payments: "Plăți",
  exportXLSING: "Exportați document ING",
  exportXLSRaiffeisen: "Exportați document Raiffeisen",
  ibanMessageError: "IBAN nu este valid",
  seriesAndNumberMessageError: "Serie și număr sunt invalide",
  importCSVStatementsING: "Importați extrase CSV ING",
  importCSVStatementsRaiffeisen: "Importați extrase CSV Raiffeisen",
  importExcel: "Importă Excel",
} as any;
