/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Button,
  Checkbox,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "../../Theme/Theme";
import { getData } from "../../Services/getData";
import { deleteData } from "../../Services/deleteData";
import DeleteQuestionnaireModal from "./DeleteQuestionnaireModal";
import { QuestionsContext } from "../../Context/questionsContext";
import UseTemplateModal from "./UseTemplateModal";
import style from "./Questionnaire.module.scss";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import EditPersonalDataForQuestionnaires from "./EditPersonalDataForQuestionnaires";
import { breadCrumbsEnum } from "../../Utils/Constants";

export default function Questionnaires() {
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalUseTemplate, setOpenModalUseTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [questionnaires, setQuestionnaires] = useState<any>([]);
  const [editMoreQuestionnaires, setEditMoreQuestionnaires] = useState([]);
  const [editMoreQuestionnairesModal, setEditMoreQuestionnairesModal] =
    useState(false);
  const [state, setState] = useState<any>({
    questionnaires: [],
    questionnairesNumber: 0,
    page: 0,
    perPage: 10,
    search: null,
    order: "asc",
    filter: null,
    preview: false,
    selectedRequest: {},
    loading: false,
  });
  const questionsContext = useContext(QuestionsContext);
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    if (questionnaires.length === 0) {
      getData(UrlEnum.questionnaire)
        .then((res) => {
          setQuestionnaires(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          notifyError(Vocabulary.getError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   */
  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs([`${breadCrumbsEnum.questionnaires}`]);
    const search = location.search.split("?");
    const newState = Object.assign({}, state);
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") newState[values[0]] = +values[1] - 1;
        else newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
      }
    });
    getQuestionnaires(newState.page, newState.perPage, newState.search);
    questionsContext.setQuestions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   *
   */
  useEffect(() => {
    if (templates.length === 0) {
      getTemplates()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.questionnaires]);

  /**
   *
   */
  function getTemplates() {
    return getData(UrlEnum.questionnaireTemplates)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
      });
  }
  /**
   *
   */
  async function getQuestionnaires(
    page: number,
    perPage: number,
    search?: string | null
  ) {
    setLoading(true);
    let url = `${UrlEnum.questionnaire}/${page}/${perPage}`;
    if (search) {
      url = `${UrlEnum.questionnaire}/${page}/${perPage}/${search}`;
    }
    await getData(url)
      .then((res) => {
        setState({
          ...state,
          questionnaires: res.data.questionnaires,
          questionnairesNumber: res.data.questionnairesNumber,
          page: page,
          perPage: perPage,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   * @param event
   * @param id
   */
  async function onChangeEditMoreQuestionnaires(event: any, id: string) {
    const newEditMoreQuestionnaires = editMoreQuestionnaires.slice() as any;
    if (event.target.checked) newEditMoreQuestionnaires.push(id);
    else {
      const index = newEditMoreQuestionnaires.findIndex(
        (value: string) => value === id
      );
      if (index >= 0) {
        newEditMoreQuestionnaires.splice(index, 1);
      }
    }
    setEditMoreQuestionnaires(newEditMoreQuestionnaires);
  }

  async function selectAllQuestionnaires(e: any) {
    if (e.target.checked) {
      const ids = questionnaires.map((q: any) => q._id);
      setEditMoreQuestionnaires(ids);
    } else {
      setEditMoreQuestionnaires([]);
    }
  }
  /**
   *
   */
  const requestHeaders = [
    {
      label: "",
      name: "",
      options: {
        customHeadLabelRender: () => (
          <Checkbox
            checked={editMoreQuestionnaires.length === questionnaires.length}
            onChange={(e) => selectAllQuestionnaires(e)}
          ></Checkbox>
        ),
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
          width: 10,
        }),
        filter: false,
        sort: false,
        selectableRowsHeader: true,
        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <Checkbox
              checked={
                editMoreQuestionnaires.find(
                  (em: string) => em === questionnaires[rowIndex]._id
                )
                  ? true
                  : false
              }
              onChange={(e) => {
                onChangeEditMoreQuestionnaires(e, questionnaires[rowIndex]._id);
              }}
            ></Checkbox>
          );
        },
      },
    },
    {
      label: Vocabulary.questionnaireName.toUpperCase(),
      name: "questionnaireName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.options.toUpperCase(),
      name: "Optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div
              style={{ marginTop: -10, marginBottom: -10, textAlign: "center" }}
            >
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      navigate(
                        `${LocalUrlEnum.questionnaires}${state.questionnaires[rowIndex]._id}`
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenDeleteModal(true);
                      setDeleteId(state.questionnaires[rowIndex]._id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      rowsPerPage: state.perPage,
      page: state.page,
      count: state.questionnairesNumber,
      search: true,
      serverSide: true,
      sort: true,
      setRowProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      onChangePage: (page: any) => {
        setState({ ...state, page: page });
        if (state.search)
          navigate(
            `${LocalUrlEnum.questionnaires}?page=${page + 1}?perPage=${
              state.perPage
            }?search=${state.search}`
          );
        else
          navigate(
            `${LocalUrlEnum.questionnaires}?page=${page + 1}?perPage=${
              state.perPage
            }`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        setState({ ...state, perPage: numberOfRows });
        if (state.search)
          navigate(
            `${
              LocalUrlEnum.questionnaires
            }?page=${1}?perPage=${numberOfRows}?search=${state.search}`
          );
        else
          navigate(
            `${LocalUrlEnum.questionnaires}?page=${1}?perPage=${numberOfRows}`
          );
        window.scrollTo(0, 0);
      },
      onSearchChange: async (search: string | null) => {
        if (search) {
          setState({ ...state, search: search });
          navigate(
            `${LocalUrlEnum.questionnaires}?page=${state.page + 1}?perPage=${
              state.perPage
            }?search=${search}`
          );
        } else {
          navigate(
            `${LocalUrlEnum.questionnaires}?page=${state.page + 1}?perPage=${
              state.perPage
            }`
          );
          setState({ ...state, search: null });
        }
      },
      onSearchClose: async () => {
        navigate(
          `${LocalUrlEnum.questionnaires}?page=${state.page + 1}?perPage=${
            state.perPage
          }`
        );
        setState({ ...state, search: null });
      },
    };
  }

  /**
   *
   */
  function handleDelete() {
    setLoading(true);
    deleteData(`${UrlEnum.questionnaire}/${deleteId}/${userName}`)
      .then(async (res) => {
        await getQuestionnaires(state.page, state.perPage);
        setLoading(false);
        setOpenDeleteModal(false);
        notifySuccess(Vocabulary.deleteSuccess);
      })
      .catch((err) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifyError(Vocabulary.deleteError);
      });
  }

  /**
   *
   */
  function handleOpenModalUseTemplate() {
    setOpenModalUseTemplate(!openModalUseTemplate);
  }

  /**
   *
   */
  function handleOpenEditPersonalDataFroQuestionnaires() {
    setEditMoreQuestionnairesModal(!editMoreQuestionnairesModal);
  }
  /**
   *
   */
  function addNewQuestionnaire() {
    navigate(`${LocalUrlEnum.questionnaires}0`);
  }

  return (
    <>
      <ToastContainer />
      <div className={style.questionnairesBtn}>
        {editMoreQuestionnaires.length !== 0 ? (
          <Button
            variant="contained"
            size="large"
            style={{
              color: theme.palette.whiteColor?.main,
              backgroundColor: theme.palette.dashboard?.main,
            }}
            onClick={handleOpenEditPersonalDataFroQuestionnaires}
          >
            {Vocabulary.editQuestionnaire}
          </Button>
        ) : (
          <p></p>
        )}
        <Button
          variant="contained"
          size="large"
          style={{
            color: theme.palette.whiteColor?.main,
            backgroundColor: theme.palette.dashboard?.main,
          }}
          onClick={() => {
            setOpenModalUseTemplate(true);
            if (templates.length === 0) addNewQuestionnaire();
          }}
        >
          {Vocabulary.newQuestionnaire}
        </Button>
      </div>
      <MUIDataTable
        title={Vocabulary.questionnaires}
        data={state.questionnaires}
        columns={requestHeaders}
        options={getTableOptions()}
      />
      {openDeleteModal ? (
        <DeleteQuestionnaireModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          handleDelete={handleDelete}
          title={Vocabulary.deleteQuestionnaire}
        />
      ) : (
        ""
      )}
      {openModalUseTemplate ? (
        <UseTemplateModal
          openModalUseTemplate={openModalUseTemplate}
          handleOpenModalUseTemplate={handleOpenModalUseTemplate}
          addNewQuestionnaire={addNewQuestionnaire}
          templates={templates}
        />
      ) : (
        ""
      )}
      {editMoreQuestionnairesModal ? (
        <EditPersonalDataForQuestionnaires
          editMoreQuestionnairesModal={editMoreQuestionnairesModal}
          editMoreQuestionnaires={editMoreQuestionnaires}
          handleOpenEditPersonalDataFroQuestionnaires={
            handleOpenEditPersonalDataFroQuestionnaires
          }
          title={"Selectați datele personale"}
        />
      ) : (
        ""
      )}
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
