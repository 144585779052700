/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { regexIBAN, regexSeriesAndNumber } from "../../Utils/Constants";
import { useState } from "react";
type PersonalDataDetailsProps = {
  title: string;
  details: any;
  gridSize: number;
  handleChangeValue: (key: string, value: string | any) => void;
};

export default function PersonalDataDetails(props: PersonalDataDetailsProps) {
  const { title, details, gridSize, handleChangeValue } = props;

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  /**
   *
   * @param value
   * @param name
   */
  const validateField = (value: string, name: string) => {
    let errorMessage = "";

    if (name === "Nr. contul IBAN") {
      const regexIBANExp = new RegExp(regexIBAN);
      if (!regexIBANExp.test(value)) {
        errorMessage = Vocabulary.ibanMessageError;
      }
    }
    if (name === "Serie și număr") {
      const regexSeriesAndNumberExp = new RegExp(regexSeriesAndNumber);
      if (!regexSeriesAndNumberExp.test(value)) {
        errorMessage = Vocabulary.seriesAndNumberMessageError;
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  return (
    <Grid item xs={12} md={gridSize} lg={gridSize}>
      <div
        style={{
          padding: 15,
        }}
      >
        <h3 style={{ margin: 10 }}>{title}</h3>
        {Object.entries(details).map((detail) => (
          <div
            key={`detail_${detail[0]}`}
            style={{ margin: 10, fontSize: "15px" }}
          >
            <TextField
              type="text"
              label={Vocabulary[detail[0]] ? Vocabulary[detail[0]] : detail[0]}
              variant="standard"
              fullWidth
              value={detail[1]}
              onChange={(e) => {
                let newValue = e.target.value;
                if (
                  (Vocabulary[detail[0]]
                    ? Vocabulary[detail[0]]
                    : detail[0]) === "Nr. contul IBAN" ||
                  (Vocabulary[detail[0]]
                    ? Vocabulary[detail[0]]
                    : detail[0]) === "Serie și număr"
                ) {
                  newValue = e.target.value.toUpperCase().replace(/\s/g, "");
                }
                handleChangeValue(detail[0], newValue);
                validateField(
                  e.target.value,
                  Vocabulary[detail[0]] ? Vocabulary[detail[0]] : detail[0]
                );
              }}
              style={{ margin: "10px 0px" }}
              error={!!errors[detail[0]]}
              helperText={errors[detail[0]]}
            />
          </div>
        ))}
      </div>
    </Grid>
  );
}
