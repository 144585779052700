export const breadCrumbsEnum = {
  users: "users",
  templates: "templates",
  questionnaires: "questionnaires",
  personalData: "personalData",
  emails: "emails",
  reports: "reports",
  orderStatus: "orderStatus",
  trashedOrders: "trashedOrders",
  orders: "orders",
  logs: "logs",
  dashboard: "dashboard",
  devices: "devices",
  settings: "settings",
};

export const regexIBAN = "^RO[0-9]{2}[A-Z]{4}[A-z0-9]{16}$";
export const regexSeriesAndNumber = "^[A-Z]{2}[0-9]{6,7}$";

