import { useEffect } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Orders.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  LocalUrlEnum,
  paymentTypeArray,
  titleForReportsTotal,
} from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";

export default function DateFilters(props) {
  const { state } = props;
  const navigate = useNavigate();
  const notifyError = (message) => toast.error(message);
  //   const navigate = useNavigate();

  useEffect(() => {
    //
  }, []);

  /**
   *
   * @param name
   * @param value
   */
  const addParamToQueryString = (name, value) => {
    const currentSearch = window.location.search;
    const searchParams = new URLSearchParams(currentSearch);
    searchParams.set(name, value);

    const newQueryString = `?${[...searchParams.entries()]
      .map(([key, val]) => `${key}=${val}`)
      .join("&")}`;

    navigate(`${window.location.pathname}${newQueryString}`);
  };

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeStartDate(event) {
    let newDate = Object.assign({}, state);
    if (moment(event).isAfter(state.endDate)) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de start după data de stop."
      );
      return;
    }
    newDate.startDate = moment(event);
    if (state.search)
      navigate(
        `${LocalUrlEnum.orders}?page=${newDate.page + 1}?perPage=${
          state.perPage
        }?filter=${newDate.filter}?sort=${newDate.sort.name},${
          newDate.sort.direction
        }?startDate=${moment(newDate.startDate._d).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(newDate.endDate._d).format(
          "YYYY-MM-DD"
        )}?paymentType=${newDate.paymentType}?search=${newDate.search}`
      );
    else
      navigate(
        `${LocalUrlEnum.orders}?page=${newDate.page + 1}?perPage=${
          newDate.perPage
        }?filter=${newDate.filter}?sort=${newDate.sort.name},${
          newDate.sort.direction
        }?startDate=${moment(newDate.startDate._d).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(newDate.endDate._d).format(
          "YYYY-MM-DD"
        )}?paymentType=${newDate.paymentType}`
      );
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeEndDate(event) {
    let newDate = Object.assign({}, state);
    if (moment(event).isAfter(moment())) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de stop după data actuală."
      );
      return;
    }
    if (moment(event).isBefore(state.startDate)) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de stop înaintea datei de start."
      );
      return;
    }
    newDate.endDate = moment(event);
    if (state.search)
      navigate(
        `${LocalUrlEnum.orders}?page=${newDate.page + 1}?perPage=${
          state.perPage
        }?filter=${newDate.filter}?sort=${newDate.sort.name},${
          newDate.sort.direction
        }?startDate=${moment(newDate.startDate._d).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(newDate.endDate._d).format(
          "YYYY-MM-DD"
        )}?paymentType=${newDate.paymentType}?search=${newDate.search}`
      );
    else
      navigate(
        `${LocalUrlEnum.orders}?page=${newDate.page + 1}?perPage=${
          newDate.perPage
        }?filter=${newDate.filter}?sort=${newDate.sort.name},${
          newDate.sort.direction
        }?startDate=${moment(newDate.startDate._d).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(newDate.endDate._d).format(
          "YYYY-MM-DD"
        )}?paymentType=${newDate.paymentType}`
      );
  }

  return (
    <Grid container spacing={3}>
      <ToastContainer />
      <Grid item xs={6} md={6} lg={6} style={{ maxWidth: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Data de start"
            inputFormat="dd-MM-yyyy"
            value={state.startDate}
            className={styles.date}
            onChange={handleChangeStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Data de stop"
            inputFormat="dd-MM-yyyy"
            value={state.endDate}
            className={styles.date}
            onChange={handleChangeEndDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <Autocomplete
          options={paymentTypeArray}
          getOptionLabel={(option) =>
            option && option !== "null" && option !== "undefined"
              ? titleForReportsTotal[option]
              : ""
          }
          isOptionEqualToValue={(option, value) => option === value}
          value={state.paymentType}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={Vocabulary.paymentType}
              size="large"
            />
          )}
          onChange={(event, newValue) => {
            addParamToQueryString("paymentType", newValue);
          }}
        />
      </Grid>
    </Grid>
  );
}
