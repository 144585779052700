/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  // Button,
  Checkbox,
  Grid,
  // List,
  // ListItem,
  // ListItemAvatar,
  // ListItemText,
  // Paper,
  // TextField,
} from "@mui/material";
// import CircleIcon from "@mui/icons-material/Circle";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import { Vocabulary } from "../../Utils/Vocabulary";
import { DeviceState } from "./Device";
// import Modal from "../../Components/Modal";
// import { useState } from "react";

type DeviceStoragesProps = {
  state: DeviceState;
  // handleChangeDefaultStorage: (defaultStorage: Array<string>) => void;
  addNewConfiguration: (storage: string) => void;
  removeConfiguration: (storage: string) => void;
};
export default function DeviceStorages(props: DeviceStoragesProps) {
  // const [openStorageModal, setOpenStorageModal] = useState(false);
  // const [newStorage, setNewStorage] = useState({
  //   name: "",
  //   index: -1,
  // });

  /**
   *
   * @param event
   * @param storage
   */
  function onStorageChange(event: any, storage: string) {
    if (event.target.checked) {
      props.addNewConfiguration(storage);
    } else props.removeConfiguration(storage);
  }

  /**
   *
   */
  // function addNewDefaultStorage(storage: any) {
  //   const newDefaultStorages = [...props.state.defaultStorages];
  //   if (storage.index >= 0) {
  //     newDefaultStorages[storage.index] = storage.name;
  //   } else {
  //     newDefaultStorages.push(storage.name);
  //   }

  //   //change
  //   props.handleChangeDefaultStorage(newDefaultStorages);
  // }

  /**
   *
   * @param storage
   */
  // function deleteDefaultStorage(index: number) {
  //   const newDefaultStorages = props.state.defaultStorages.filter(
  //     (storage: any, i: number) => i !== index
  //   );
  //   //change
  //   props.handleChangeDefaultStorage(newDefaultStorages);
  // }
  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        {/* <Paper> */}
          <h2
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              padding: "20px 0px 0px 15px",
            }}
          >
            {Vocabulary.storage}
            {/* <Button
              variant="contained"
              size="small"
              onClick={() => setOpenStorageModal(true)}
              style={{ fontSize: 13, marginLeft: 15 }}
            >
              {Vocabulary.change}
              <EditIcon
                fontSize="medium"
                style={{ marginLeft: 15, verticalAlign: "center" }}
              />
            </Button> */}
          </h2>

          <Grid container spacing={3} style={{ padding: 10 }}>
            {props.state.defaultStorages.map((storage: any) => {
              return (
                <Grid key={`storage${storage}`} item xs={12} md={3} lg={3}>
                  <Checkbox
                    color="primary"
                    checked={
                      props.state.configurations.findIndex(
                        (conf) =>
                          conf.storage.includes(storage) ||
                          storage.includes(conf.storage)
                      ) >= 0
                    }
                    onChange={(e) => onStorageChange(e, storage)}
                  />
                  {storage}
                </Grid>
              );
            })}
          </Grid>
        {/* </Paper> */}
      </Grid>
      {/* {openStorageModal ? (
        <Modal
          open={openStorageModal}
          onClose={() => setOpenStorageModal(false)}
          title={`${Vocabulary.change} ${Vocabulary.storage}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              type="text"
              name={Vocabulary.storage}
              label={Vocabulary.storage}
              variant="standard"
              value={newStorage.name}
              onChange={(e) =>
                setNewStorage({ ...newStorage, name: e.target.value })
              }
            />
            {newStorage.index >= 0 ? (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  addNewDefaultStorage(newStorage);
                  setNewStorage({ name: "", index: -1 });
                }}
                style={{ fontSize: 13, marginLeft: 15 }}
              >
                {Vocabulary.change}
                <EditIcon
                  fontSize="medium"
                  style={{ marginLeft: 15, verticalAlign: "center" }}
                />
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  addNewDefaultStorage(newStorage);
                  setNewStorage({ name: "", index: -1 });
                }}
                style={{ fontSize: 13, marginLeft: 15 }}
              >
                {Vocabulary.add}
                <AddCircleIcon
                  style={{ marginLeft: 10, verticalAlign: "end" }}
                />
              </Button>
            )}
          </div>
          <List>
            {props.state.defaultStorages.map((storage: any, index: number) => {
              return (
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    margin: 10,
                  }}
                  secondaryAction={
                    <div>
                      <EditIcon
                        fontSize="medium"
                        style={{
                          marginLeft: 15,
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setNewStorage({ name: storage, index: index })
                        }
                      />
                      <DeleteIcon
                        fontSize="medium"
                        style={{
                          marginLeft: 15,
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteDefaultStorage(index)}
                      />
                    </div>
                  }
                >
                  <ListItemAvatar>
                    <CircleIcon fontSize="small" style={{ fontSize: 9 }} />
                  </ListItemAvatar>
                  <ListItemText primary={storage} />
                </ListItem>
              );
            })}
          </List>
        </Modal>
      ) : null} */}
    </>
  );
}
