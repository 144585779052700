/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { BreadCrumbsContext } from "../Context/breadCrumbsContext";
import { breadCrumbsEnum } from "../Utils/Constants";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Vocabulary } from "../Utils/Vocabulary";
import { Button, Grid } from "@mui/material";
import { getData } from "../Services/getData";
import { UrlEnum } from "../Utils/Utils";
import { postData } from "../Services/postData";

export default function Settings() {
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const [settings, setSettings] = useState<any>(null);

  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs([`${breadCrumbsEnum.settings}`]);
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  async function getSettings() {
    const res = await getData(UrlEnum.settings);
    if (res && res.data) {
      setSettings(res.data);
    }
  }

  /**
   *
   */
  async function addOrUpdateSettings() {
    await postData(UrlEnum.settings, settings);
  }

  return (
    <ValidatorForm onSubmit={addOrUpdateSettings}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <TextValidator
            label={Vocabulary.accountING}
            name="email"
            type="text"
            size="small"
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            value={settings?.accountING || ""}
            onChange={(e: any) =>
              setSettings({ ...settings, accountING: e.target.value })
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextValidator
            label={Vocabulary.accountRaiffeisen}
            name="email"
            type="text"
            size="small"
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            value={settings?.accountRaiffeisen || ""}
            onChange={(e: any) =>
              setSettings({ ...settings, accountRaiffeisen: e.target.value })
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Button type="submit" variant="contained">
            {Vocabulary.save}
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
