/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Button } from "@mui/material";
import { Vocabulary } from "../Utils/Vocabulary";
import { handleFileUpload } from "../Utils/Utils";
import Modal from "./Modal";

type ImportExcelModalProps = {
  open: boolean;
  range: number;
  title: string;
  onClose: () => void;
  saveItems: (items: any) => void;
};

export default function ImportExcelModal(props: ImportExcelModalProps) {
  const { open, range, title, onClose, saveItems } = props;
  const [items, setItems] = useState<any>([]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${Vocabulary.importExcel} ${title}`}
    >
      <input
        type="file"
        onChange={(e: any) => {
          handleFileUpload(e, range, (newData: any) => {
            setItems(newData);
          });
        }}
      />

      <Button
        variant="contained"
        onClick={() => saveItems(items)}
        disabled={items.length === 0 ? true : false}
        style={{ marginTop: 20 }}
      >
        {Vocabulary.save}
      </Button>
    </Modal>
  );
}
