/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ChooseDevicesModal from "./ChooseDevicesModal";
import AddAndShowImages from "./AddAndShowImages";
import DeviceGeneralInformation from "./DeviceGeneralInformation";
import DeviceStorages from "./DeviceStorages";
import DeviceColors from "./DeviceColors";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../Services/postData";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { getData } from "../../Services/getData";
import AssociatedWithAQuestionnaire from "./AssociatedWithAQuestionnaire";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "./Devices.module.scss";
import theme from "../../Theme/Theme";
import SaveIcon from "@mui/icons-material/Save";
import moment from "moment";
import { breadCrumbsEnum } from "../../Utils/Constants";

export type DeviceConfig = {
  color: string;
  price: number;
};

export type DeviceConfigState = {
  storage: string;
  colors: Array<DeviceConfig>;
};

export type DeviceState = {
  _id?: string;
  images: Array<any>;
  files: Array<any>;
  deviceType: string;
  manufacturer: string;
  model: string;
  deviceName: string;
  paymentMethod: string;
  defaultColors: Array<string>;
  defaultStorages: Array<string>;
  configurations: Array<any>;
  questionnaireId?: string | null;
  released: Date;
};

function Device() {
  const navigate = useNavigate();
  const location = useLocation();
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [devicesResponse, setDevicesResponse] = useState<Array<any>>([]);
  const [openChooseDevicesModal, setOpenChooseDevicesModal] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);
  const [loading, setLoading] = useState(false);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [dropzoneFiles, setDropzoneFiles] = useState<Array<any>>([]);
  const [associatedQuestionnaire, setAssociatedQuestionnaire] = useState("");
  const [state, setState] = useState<DeviceState>({
    images: [],
    files: [],
    deviceType: "",
    manufacturer: "",
    model: "",
    deviceName: "",
    paymentMethod: "",
    defaultColors: ["Orice culoare"],
    defaultStorages: ["64GB", "128GB", "256GB", "512GB"],
    configurations: [],
    questionnaireId: null,
    released: new Date(),
  });
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    let id = location.pathname;
    id = id.replace("/devices/", "");
    if (id === "0") {
      breadCrumbsContext.setBreadCrumbs([
        `${breadCrumbsEnum.devices}`,
        `${Vocabulary.newDevice}`,
      ]);
    }
    if (id !== "0" && !hasInformation()) {
      getDevice(id);
    }
    if (questionnaires.length === 0) {
      getQuestionnaires();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param id
   */
  async function getDevice(id: string) {
    setLoading(true);
    await getData(`${UrlEnum.devices}/${id}`)
      .then((res) => {
        let newState = Object.assign({}, state) as DeviceState;
        newState = { ...res.data[0] };
        newState.files = [];
        newState.defaultColors = res.data[0].defaultColors.split(", ");
        newState.defaultColors = newState.defaultColors.filter(
          (dc) => dc !== ""
        );
        newState.defaultStorages = res.data[0].defaultStorages.split(", ");
        newState.defaultStorages = newState.defaultStorages.filter(
          (ds) => ds !== ""
        );
        breadCrumbsContext.setBreadCrumbs([
          `${breadCrumbsEnum.devices}`,
          `${newState.deviceName}`,
        ]);
        setShowDropzone(false);
        setState(newState);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   */
  async function getQuestionnaires() {
    setLoading(true);
    await getData(`${UrlEnum.questionnaire}`)
      .then((res) => {
        setQuestionnaires(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   * @returns
   */
  function hasInformation() {
    if (state.deviceName === "" && state.deviceType === "") return false;
    return true;
  }

  /**
   *
   * @param storage
   */
  function addNewConfiguration(storage: string) {
    const newState = Object.assign({}, state) as DeviceState;
    newState.configurations.push({
      storage: storage,
      colors: state.defaultColors.map((dc: string) => ({
        color: dc,
        price: "",
      })),
    });
    setState(newState);
  }

  /**
   *
   * @param storage
   */
  function removeConfiguration(storage: string) {
    const newState = Object.assign({}, state) as DeviceState;
    const index = newState.configurations.findIndex(
      (config) =>
        config.storage.includes(storage) || storage.includes(config.storage)
    );
    if (index >= 0) {
      newState.configurations.splice(index, 1);
      setState(newState);
    }
  }

  /**
   *
   * @param event
   */
  function handleChangeState(event: any) {
    const newState = Object.assign({}, state) as any;
    newState[event.target.name] = event.target.value;
    setState(newState);
  }

  /**
   *
   * @param event
   * @param config
   */
  function handleChangeColorName(event: any, config: DeviceConfigState) {
    const newState = Object.assign({}, state) as DeviceState;
    const index = newState.configurations.findIndex(
      (conf) => conf.storage === config.storage
    );
    if (index >= 0) {
      newState.configurations[index].colors[event.target.name].color =
        event.target.value;
      setState(newState);
    }
  }

  /**
   *
   * @param event
   */
  function handleChangePrice(event: any, config: DeviceConfigState) {
    const newState = Object.assign({}, state) as DeviceState;
    const index = newState.configurations.findIndex(
      (conf) => conf.storage === config.storage
    );
    if (index >= 0) {
      if (event.target.value !== "on") {
        if (event.target.name === "0") {
          newState.configurations[index].colors.forEach(
            (color: any) => (color.price = event.target.value)
          );
        } else {
          newState.configurations[index].colors[event.target.name].price =
            event.target.value;
          newState.configurations[index].colors[0].price = "";
        }
      } else if (event.target.checked) {
        if (event.target.name === "0") {
          newState.configurations[index].colors.forEach(
            (color: any) => (color.price = 0)
          );
        } else {
          newState.configurations[index].colors[event.target.name].price = 0;
          newState.configurations[index].colors[0].price = "";
        }
      } else if (event.target.name === "0") {
        newState.configurations[index].colors.forEach(
          (color: any) => (color.price = "")
        );
      } else {
        newState.configurations[index].colors[event.target.name].price = "";
        newState.configurations[index].colors[0].price = "";
      }
      setState(newState);
    }
  }

  /**
   *
   */
  function handleCloseChooseDevicesModal() {
    setOpenChooseDevicesModal(false);
  }

  function getDefaultColors() {
    const colors = new Set();
    for (let i = 0; i < state.configurations.length; i++) {
      state.configurations[i].colors.forEach((color: any) => {
        colors.add(color.color);
      });
    }
    const newColors = Array.from(colors);
    return newColors.join(", ");
  }
  /**
   *
   */
  function handleSubmit() {
    setLoading(true);
    if (state.files.length === 0 && state.images.length === 0) {
      notifyError(Vocabulary.pleaseAddImage);
      setLoading(false);
      return;
    }
    if (state.configurations.length === 0) {
      notifyError(Vocabulary.pleaseAddStorageAndColor);
      setLoading(false);
      return;
    }
    let url = `${UrlEnum.devices}`;
    const formData = new FormData() as any;
    formData.append("deviceName", state.deviceName);
    formData.append("deviceType", state.deviceType);
    formData.append("manufacturer", state.manufacturer);
    formData.append("model", state.model);
    formData.append("released", state.released);
    formData.append("paymentMethod", state.paymentMethod);
    if (state.files && state.files.length !== 0)
      state.files.forEach((file) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", []);

    const defaultColors = getDefaultColors();
    formData.append("defaultColors", defaultColors);
    const defaultStorages = state.defaultStorages.join(", ");
    formData.append("defaultStorages", defaultStorages);
    formData.append("configurations", JSON.stringify(state.configurations));
    formData.append("userName", userName);
    if (associatedQuestionnaire !== "") {
      const assQuestionnaire = associatedQuestionnaire.split("_");
      formData.append("questionnaireId", assQuestionnaire[0]);
    } else if (state.questionnaireId) {
      formData.append("questionnaireId", state.questionnaireId);
    }
    if (state._id) {
      const images = state.images.filter((img) => typeof img === "string");
      formData.append("images", JSON.stringify(images));
      formData.append("id", state._id);
      url = `${UrlEnum.devices}/${state._id}`;
    }
    postData(url, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          notifySuccess(Vocabulary.saveSuccess);
          if (!state._id) navigate(`${LocalUrlEnum.devices}${res.data._id}`);
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.saveError);
      });
  }

  /**
   *
   * @param e
   */
  function handleChangeAssociatedValue(e: any) {
    setAssociatedQuestionnaire(e.target.value);
  }

  /**
   *
   */
  function editAssociatedQuestionnaire() {
    setState({ ...state, questionnaireId: "" });
  }

  /**
   *
   */
  function handleDeleteImages(newImageList: any, newFileList: any) {
    setState({
      ...state,
      images: newImageList,
      files: newFileList,
    });
  }

  /**
   *
   * @param files
   */
  function handleChangeFiles(files: any) {
    setState({ ...state, files: files });
  }

  /**
   *
   */
  function showImages() {
    setState({
      ...state,
      images: [...state.images, ...dropzoneFiles] as any,
    });
    setShowDropzone(false);
  }

  /**
   *
   * @param event
   * @returns
   */
  function handleChangeDate(event: any) {
    const newState = Object.assign({}, state);
    if (moment(event).isAfter(moment())) {
      notifyError(
        "Data este invalidă! Nu puteți alege data de stop după data actuală."
      );
      return;
    }
    newState.released = new Date(event);
    setState(newState);
  }

  /**
   *
   * @param storage
   */
  // function handleChangeDefaultStorage(storage: any) {
  //   const newState = Object.assign({}, state);
  //   newState.defaultStorages = storage;
  //   setState(newState);
  // }
  return (
    <>
      <ToastContainer />
      <div className={style.deviceContainer}>
        <AssociatedWithAQuestionnaire
          questionnaires={questionnaires}
          associatedQuestionnaire={associatedQuestionnaire}
          handleChangeAssociatedValue={handleChangeAssociatedValue}
          questionnaireId={state.questionnaireId}
          editAssociatedQuestionnaire={editAssociatedQuestionnaire}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Data de lansare"
            inputFormat="dd-MM-yyyy"
            value={state.released}
            onChange={handleChangeDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper>
            <h2 style={{ padding: "20px 0px 0px 15px" }}>
              {Vocabulary.params}
            </h2>
            <Grid container spacing={3}>
              <AddAndShowImages
                state={state}
                gridSize={6}
                showDropzone={showDropzone}
                setDropzoneFiles={setDropzoneFiles}
                setShowDropzone={setShowDropzone}
                handleDeleteImages={handleDeleteImages}
                handleChangeFiles={handleChangeFiles}
                showImages={showImages}
              />
              <DeviceGeneralInformation
                state={state}
                setState={setState}
                setDevicesResponse={setDevicesResponse}
                setOpenChooseDevicesModal={setOpenChooseDevicesModal}
                handleChangeState={handleChangeState}
                setLoading={setLoading}
              />
            </Grid>
          </Paper>
        </Grid>
        <DeviceStorages
          state={state}
          // handleChangeDefaultStorage={handleChangeDefaultStorage}
          addNewConfiguration={addNewConfiguration}
          removeConfiguration={removeConfiguration}
        />
        <Grid item xs={12} md={12} style={{ marginBottom: 100 }}>
          <Grid container spacing={4}>
            {state.configurations.map(
              (config: DeviceConfigState, index: number) => {
                return (
                  <DeviceColors
                    key={index}
                    config={config}
                    handleChangePrice={handleChangePrice}
                    handleChangeColorName={handleChangeColorName}
                  />
                );
              }
            )}
          </Grid>
        </Grid>
      </Grid>
      <ChooseDevicesModal
        state={state}
        setState={setState}
        devices={devicesResponse}
        open={openChooseDevicesModal}
        handleClose={handleCloseChooseDevicesModal}
        setLoading={setLoading}
      />
      <div
        style={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          left: 240,
          width: "calc(100% - 240px)",
          textAlign: "center",
          boxShadow: "0px -2px 6px #00000033",
          zIndex: 2,
        }}
      >
        <Button
          variant="contained"
          size="large"
          style={{
            margin: 20,
            color: theme.palette.whiteColor?.main,
          }}
          // className={style.deviceSaveBtn}
          onClick={handleSubmit}
        >
          <SaveIcon /> &nbsp;&nbsp; {Vocabulary.save}
        </Button>
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Device;
