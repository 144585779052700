/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, Grid, Paper, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { QuestionsContext } from "../../Context/questionsContext";
import { postData } from "../../Services/postData";
import { LocalUrlEnum, UrlEnum } from "../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "../../Services/getData";
import QuestionnaireMenu from "../Questionnaire/QuestionnaireMenu";
import Question from "../Questionnaire/Question";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import { breadCrumbsEnum } from "../../Utils/Constants";

export default function QuestionnaireTemplate() {
  const questionsContext = useContext(QuestionsContext);
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const [template, setTemplate] = useState({
    id: "0",
    templateName: "",
  });
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  useEffect(() => {
    let id = location.pathname;
    id = id.replace("/templates/questionnaires/", "");
    if (id !== "0" && template.templateName === "") {
      getTemplate(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param id
   */
  async function getTemplate(id: string) {
    setLoading(true);
    await getData(`${UrlEnum.questionnaireTemplates}/${id}`)
      .then((res) => {
        const newTemplate = {
          id: res.data[0]._id,
          templateName: res.data[0].templateName,
        };
        breadCrumbsContext.setBreadCrumbs([
          `${breadCrumbsEnum.templates}/${breadCrumbsEnum.questionnaires}`,
          `${newTemplate.templateName}`,
        ]);
        setTemplate(newTemplate);
        questionsContext.setQuestions(res.data[0].questions);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notifyError(Vocabulary.getError);
      });
  }

  /**
   *
   */
  function saveQuestionnaires(event: any) {
    event.preventDefault();
    setLoading(true);
    const NewTemplate = {
      templateName: template.templateName,
      questions: questionsContext.questions,
      userName: userName,
    };
    let url = UrlEnum.questionnaireTemplates;
    if (template.id !== "0") {
      url = `${UrlEnum.questionnaireTemplates}/${template.id}`;
    }
    postData(url, NewTemplate)
      .then((res) => {
        navigate(`${LocalUrlEnum.questionnairesTemplates}${res.data._id}`);
        notifySuccess(Vocabulary.saveSuccess);
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.saveError);
        setLoading(false);
      });
  }

  return (
    <>
      <ToastContainer />
      <form onSubmit={saveQuestionnaires}>
        <Grid container spacing={2} style={{ marginRight: 0 }}>
          <QuestionnaireMenu
            isQuestionnaireTemplate={true}
            questionnaireName={template.templateName}
          />
          <Grid
            item
            xs={10}
            style={{
              height: "calc(100vh - 102px)",
              overflowY: "auto",
              marginTop: 9,
            }}
          >
            <Paper style={{ padding: 25, width: "99%" }}>
              <TextField
                type="text"
                name="templateName"
                label={Vocabulary.templateName}
                variant="standard"
                value={template.templateName}
                onChange={(e) =>
                  setTemplate({
                    ...template,
                    templateName: e.target.value,
                  })
                }
                required
                fullWidth
              />
            </Paper>
            {questionsContext.questions.map((question: any, index: number) => (
              <Paper
                key={`questionsContext.questions_${index}`}
                style={{ padding: 15, margin: "15px 0px", width: "99%" }}
              >
                <Question
                  questionNumber={index}
                  questions={questionsContext.questions}
                />
              </Paper>
            ))}
          </Grid>
        </Grid>
      </form>
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
